// Create a new Navigation object
class SidebarFilters {
  // Initialize object properties
  constructor() {
    this.openBtn = $( '.filters-open-btn' );
    this.closeBtn = $( '.filters-close-btn' );
    this.showResultsBtn = $( '.filters-show-btn' );
    this.clearBtn = $( '.filters-clear-btn' );
    this.sidebar = $( '.sidebar-filters' );
    this.widgets = $( '.filters-widgets' );
    this.header = $( '.primary-header' );
    this.page = $( 'body' );
  }

  /**
   * Portable function that contains logic to open mobile sidebar
   */
  openSidebar() {
    // Initiate slide up animation
    this.sidebar.addClass('mobile-show').addClass('mobile-slide-up');

    // Prevent body scroll
    this.page.addClass( 'no-scroll' );

    // Reset position in relation to site header
    this.positionSidebar();

    // End slide up animation after delay
    setTimeout(function() {
      this.sidebar.removeClass( 'mobile-slide-up' );
    }.bind(this), 750); // Bind the class object to keep context
  }

  /**
   * Portable function that contains logic to close mobile sidebar
   */
  closeSidebar() {
    // Initiate slide down animation
    this.sidebar.removeClass('mobile-show').addClass('mobile-slide-down');

    // Re-enable body scroll
    this.page.removeClass( 'no-scroll' );

    // End slide down animation after delay
    setTimeout(function() {
      this.sidebar.removeClass( 'mobile-slide-down' );
    }.bind(this), 750); // Bind the class object to keep context
  }

  /**
   * Initialize the open filters button for mobile
   */
  initOpenTrigger() {
    this.openBtn.on( 'click', function(e) {
      e.preventDefault();
      this.openSidebar();
    }.bind(this)); // Bind the class object to keep context
  }

  /**
   * Initialize the close for mobile
   */
  initCloseTrigger() {
    this.closeBtn.on( 'click', function(e) {
      e.preventDefault();
      this.closeSidebar();
    }.bind(this)); // Bind the class object to keep context
  }

  /**
   * Position (absolute) the mobile filters in relation to the primary site header
   */
  positionSidebar() {
    var topPosition = 0;
    var headerHeight = this.header.outerHeight( true );
    var adminBar = 0;

    if (this.page.hasClass( 'admin-bar' )) {
      adminBar = $( '#wpadminbar' ).outerHeight( true );
    }

    topPosition = parseFloat( headerHeight ) + parseFloat( adminBar );

    this.sidebar.css( '--calculated-top', topPosition + 'px' );
  }

  /**
   * Handle page resize callback
   */
  handleResize() {
    if (this.sidebar.hasClass( 'mobile-show' )) {
      this.closeSidebar();
    }
  }

  /**
   * Method callback to initialize the Navigation object
   */
  init() {
    this.initOpenTrigger();
    this.initCloseTrigger();

    // Add window resize listener with debounce
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 1000 );
    }.bind( this )); // Bind the class object to keep context
  }
}

// Export class as default
export default SidebarFilters;
