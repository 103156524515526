// Fix incorrect class being applied
class WoocommerceHandpicked {
  // Initialize object properties
  constructor() {
    this.handpickedWrapper = $('.wp-block-handpicked-products');
    this.wrapperHasColumnsClass = $(this.handpickedWrapper.hasClass('.has-2-columns'));
    this.handpickedColumns = $(this.handpickedWrapper.data('columns'));
  }

  updateColumnClass() {
    if (this.wrapperHasColumnsClass) {
      this.handpickedWrapper.removeClass('has-2-columns').addClass('has-'+this.handpickedColumns[0]+'-columns');
    }
  }

  /**
   * Method callback to initialize...
   */
  init() {
    this.updateColumnClass();
  }
}

// Export class as default
export default WoocommerceHandpicked;
