class RepositionSaleBadge {

  constructor() {
    this.singleSaleBadge = $('.single-product .product > .onsale');
    this.productSummary = $('.gallery-summary-wrap .entry-summary');
  }

  /**
   * Move sale badge to summary wrap .entry-summary container
   */
  moveSaleBadge() {
    if (this.singleSaleBadge) {
      this.singleSaleBadge.prependTo(this.productSummary);
    }
  }

  /**
   * Initialize function to reposition sale flash
   */
  init() {
    this.moveSaleBadge();
  }
}

export default RepositionSaleBadge;
