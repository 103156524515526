/**
 * Creates a new FeatureSlider element
 */
class FeatureSlider {
  constructor() {
    this.step = $('.step');
    this.image = $('.image');

    this.currentStep = 1;

    this.autoplay = null;
  }

  init () {
    // Add event listeners to steps
    this.step.on('click', function (e) {
      this.currentStep = $(e.currentTarget).data('step')
      this.setCurrent();
    }.bind(this));

    // Set the first step to Active on load
    this.setCurrent();

    // Begin autoplay
    this.startAutoplay();
  }


  /**
   * Changes the "slide" to the class' current step
   */
  setCurrent() {
    // Remove active from all other steps
    this.step.removeClass('active');

    // Add the active class to the current step element
    this.step.filter('[data-step=' + this.currentStep + ']').addClass('active');

    // Remove all active images
    this.image.removeClass('active');

    // Change active image to match the current step
    this.image.filter('[data-step=' + this.currentStep + ']').addClass('active');
  }

  startAutoplay() {
    this.autoplay = setInterval(() => {
      if (this.currentStep === this.step.length) {
        this.currentStep = 1;
      } else {
        this.currentStep++;
      }
      this.setCurrent();
    }, 7000);
  }

  stopAutoplay() {
    clearInterval(this.autoplay);
    this.autoplay = null;
  }
}

// Export class as default
export default FeatureSlider;
