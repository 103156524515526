/** Clone and convert the WooCommerce unordered list on the My Account page to a select element */
class MyAccountNavigation {
  /**
   * Create object with existing navigation and a clone of existing navigation.
   */
  constructor() {
    this.accountNav = $('.woocommerce-MyAccount-navigation ul');
    this.accountNavSelect = this.accountNav.clone().insertBefore(this.accountNav);
  }

  /**
   * Convert navigation clone from unordered list to a select element.
   */
  createSelectMenu() {
    if (this.accountNavSelect) {
      this.accountNavSelect.each(function () {
        var list = $(this),
          // Create select element
          select = $(document.createElement('select')).insertBefore($(this).hide()).change(function () {
            window.location.href = $(this).val();
          });
        $('>li a', this).each(function () {
          // Create option elements
          var option = $(document.createElement('option'))
            .appendTo(select)
            .val(this.href)
            .html($(this).html());
          // Set current option to selected
          if (option.val() === window.location.href) {
            option.attr('selected', 'selected');
          }
        });
        list.remove();
      });
    }
  }

  /**
   * Initialize function to create select menu
   */
  init() {
    this.createSelectMenu();
  }
}

export default MyAccountNavigation;
