/**
 * Creates a new Navigation object.
 * @class Navigation
 */
class Navigation {

  /**
   * Initialize object properties and options.
   */
  constructor(nav, navToggle, dropdown, dropdownToggle, dropdownMenu, dropdownItem) {
    /**
     * Initialize the Navigation object with all of its object elements.
     * @param {Object} nav - The main navigation element `<nav>`.
     * @param {Object} navToggle - The mobile navigation toggle button.
     * @param {Object} dropdown - Parent navigation items with dropdown menu.
     * @param {Object} dropdownToggle - Caret element that acts as the dropdown toggle button.
     * @param {Object} dropdownMenu - Dropdown menu element containing dropdown menu items.
     * @param {Object} dropdownItem - Dropdown menu item.
     */
    this.nav = nav;
    this.navToggle = navToggle;
    this.dropdown = dropdown;
    this.dropdownToggle = dropdownToggle;
    this.dropdownMenu = dropdownMenu;
    this.dropdownItem = dropdownItem;

    /* Object property that gets/sets responsive breakpoints (screen sizes). */
    this.screen = undefined;
  }

  /**
   * Initialize the mobile menu toggle button.
   */
  initToggleNav() {
    /* On click listener, toggle all relevant open/close classes. */
    this.navToggle.on( 'click', function(e) {
      e.preventDefault();
      this.navToggle.toggleClass( 'open' );
      this.nav.toggleClass( 'open' );
      $( 'body' ).toggleClass( 'no-scroll' );
    }.bind( this )); // Bind the class object to maintain context.

    return;
  }

  /**
   * Initialize navigation dropdowns.
   * This method calculates the cumulative dropdown height and applies the calculated height
   * to the wrapping element. This is necessary for the CSS slide up/down transition property to work.
   */
  initDropdowns() {
    /**
     * Binding the class object will not work here due to the fact that `this` is needed inside the callback.
     * So, use the `that | self` var method to allow `this` to keep context.
     */
    let self = this;

    let dropdownHeight = 0;

    /* Loop through all the nav-items to find dropdowns. */
    this.dropdown.each( function() {
      /* Loop through all the dropdown-items to cumulatively calculate dropdown height. */
      $( this ).find( self.dropdownMenu ).find( self.dropdownItem ).each( function() {
        dropdownHeight = parseFloat( dropdownHeight ) + parseFloat( $( this ).outerHeight( true ) );
      });

      /* Apply the cumulative dropdown height to this iteration. */
      $( this ).find( self.dropdownMenu ).css( '--calculated-height', dropdownHeight + 'px' );

      /* Reset the dropdown height for the next iteration. */
      dropdownHeight = 0;
    });

    return;
  }

  /**
   * Initialize the mobile menu dropdown togglers (carets).
   */
  initToggleDropdowns() {
    /* On click listener to open/close dropdown sub menus. */
    this.dropdownToggle.on( 'click', function( e ) {
      e.preventDefault();

      /* Open parent dropdown. */
      $( this ).parent().parent().toggleClass( 'open' );

      /* Close any open sibling dropdown. */
      // $( this ).parent().parent().siblings().find( this.dropdown ).removeClass( 'open' );

      /* Open the target dropdown-menu. */
      $( this ).parent().siblings( this.dropdownMenu ).toggleClass( 'open' );
    });

    return;
  }

  /**
   * Initialize desktop menu dropdown hover listener.
   */
  initHoverListener() {
    if ( this.screen === 'mobile' ) {
      this.dropdown.off('mouseenter mouseleave');

      return;
    }

    /**
     * Binding the class object will not work here due to the fact that `this` is needed inside the callback.
     * So, use the `that | self` var method to allow `this` to keep context.
     */
    let self = this;

    /* On mouseover listener to open dropdowns */
    this.dropdown.on( 'mouseenter focusin', function() {
      $( this ).find( self.dropdownMenu ).addClass( 'open' );
    });

    /* On mouseleave listener to close dropdowns */
    this.dropdown.on( 'mouseleave focusout', function() {
      $( this ).find( self.dropdownMenu ).removeClass( 'open' );
    });

    return;
  }

  /**
   * Gets/sets the screen break type.
   */
  initScreen() {
    this.screen = this.navToggle.is( ':visible' ) ? 'mobile' : 'desktop';

    return;
  }

  /**
   * Handle page resize callbacks.
   */
  handleResize() {
    /* Close any open nav elements. */
    this.nav.removeClass( 'open' );
    this.navToggle.removeClass( 'open' );
    this.dropdownMenu.removeClass( 'open' );
    this.dropdown.removeClass( 'open' );
    $( 'body' ).removeClass( 'no-scroll' );

    /* Re-establish the screen break size. */
    this.initScreen();

    /* Re-calculate dropdown dimensions and positioning. */
    this.initDropdowns();

    /* Re-evaluate if hover listeners are needed for binding or unbinding. */
    this.initHoverListener();

    return;
  }

  /**
   * Handle page scroll callbacks.
   */
  handleScroll() {
    //
    return;
  }

  /**
   * Method callback to initialize the Navigation object.
   */
  init() {
    this.initScreen();
    this.initToggleNav();
    this.initDropdowns();
    this.initToggleDropdowns();
    this.initHoverListener();

    /* Add window resize listener with debounce. */
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 500 );
    }.bind( this )); // Bind the class object to maintain context.

    /* Add window scroll listener. */
    $( window ).on( 'scroll', function() {
      this.handleScroll();
    }.bind( this )); // Bind the class object to maintain context.
  }
}

/* Export the class as default. */
export default Navigation;
