/* Import custom object and events. */
import Navigation from '../components/navigation';

/* Import SearchForm Filters class. */
import SearchForm from '../components/searchform';

/* Import Sidebar Filters class. */
import SidebarFilters from '../components/sidebar-filters';

/* Import Feature Sliders class */
import FeatureSlider from '../components/feature-slider';

/* Import WooCommerce Handpicked class */
import WoocommerceHandpicked from '../components/wc-handpicked';

/* Import My Account Navigation class */
import MyAccountNavigation from '../woocommerce/account-navigation';

/* Import Resition Sale Badge class */
import RepositionSaleBadge from '../woocommerce/reposition-sale-badge';

export default {
  init() {
    // JavaScript to be fired on all pages

    /**
     * Construct and initialize the Navigation object with defined options.
     */
    const navigation = new Navigation(
      this.nav = $( '.nav' ),
      this.navToggle = $( '.nav-toggle' ),
      this.dropdown = $( '.dropdown' ),
      this.dropdownToggle = $( '.dropdown-toggle' ),
      this.dropdownMenu = $( '.dropdown-menu' ),
      this.dropdownItem = $( '.dropdown-item' )
    );
    navigation.init();

    /**
     * Construct and initialize the Navigation object with defined options.
     */
    const searchform = new SearchForm(
      this.searchToggle = $( '.search-toggle' ),
      this.searchDropdown = $( '.search-dropdown' )
    );
    searchform.init();

    /**
     * Initialize the siderbar filters object
     */
    const sidebarFilters = new SidebarFilters();
    sidebarFilters.init();

    /**
     * Initialize the feature slider object
     */
    const sliderFeature = new FeatureSlider();
    sliderFeature.init();

    /**
     * Initialize WooCommerce Handpicked fix
     */
    const woocommerceHandpicked = new WoocommerceHandpicked();
    woocommerceHandpicked.init();

    /**
     * Initialize My Account Nagivation
     */
    const myAccountNavigation = new MyAccountNavigation();
    myAccountNavigation.init();

    /**
     * Initialize Reposition Sale Badge
     */
    const repositionSaleBadge = new RepositionSaleBadge();
    repositionSaleBadge.init();


},
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
