/**
 * Creates a new SearchForm object.
 * @class SearchForm
 */
class SearchForm {

  /**
   * Initialize object properties and options.
   */
  constructor(searchToggle, searchDropdown) {
    /**
     * Initialize the SearchForm object with all of its object elements.
     * @param {Object} searchToggle - The search toggle button.
     * @param {Object} searchDropdown - The search dropdown component with form.
     */
    this.searchToggle = searchToggle;
    this.searchDropdown = searchDropdown;

    /* Object property that gets/sets responsive breakpoints (screen sizes). */
    this.screen = undefined;
  }

  /**
   * Initialize the search form dropdown toggler (icon)
   */
  initToggleSearch() {
    // On click listener to open/close search form dropdown
    this.searchToggle.on( 'click', function( e ) {
      e.preventDefault();
      this.searchDropdown.toggleClass( 'open' );
      this.searchToggle.toggleClass( 'open' );
    }.bind( this )); // Bind the class object to keep context
  }

  /**
   * Handle page resize callbacks.
   */
  handleResize() {
    /* Close any open search elements. */
    this.searchToggle.removeClass( 'open' );
    this.searchDropdown.removeClass( 'open' );

    return;
  }

  /**
   * Handle page scroll callbacks.
   */
  handleScroll() {
    //
    return;
  }

  /**
   * Method callback to initialize the Navigation object.
   */
  init() {
    this.initToggleSearch();

    /* Add window resize listener with debounce. */
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 500 );
    }.bind( this )); // Bind the class object to maintain context.

    /* Add window scroll listener. */
    $( window ).on( 'scroll', function() {
      this.handleScroll();
    }.bind( this )); // Bind the class object to maintain context.
  }
}

/* Export the class as default. */
export default SearchForm;
